import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

type ModalContextProps = {
  children?: ReactNode;
  visible: boolean;
  width: number;
  height: number;
  showModal: (children: ReactNode, width?: number, height?: number) => void;
  hideModal: () => void;
};

const initialValue: ModalContextProps = {
  children: "",
  visible: false,
  width: 400,
  height: 400,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideModal: () => {},
};

const ModalContext = createContext<ModalContextProps>(initialValue);

type ContextProviderProps = {
  children: ReactNode;
};

const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const [value, setValue] = useState<{
    children: ReactNode;
    visible: boolean;
    width: number;
    height: number;
  }>({
    visible: false,
    children: "",
    width: 400,
    height: 400,
  });

  const showModal = useCallback(
    (newChildren: ReactNode, width = 400, height = 400) => {
      setValue({ children: newChildren, width, height, visible: true });
    },
    []
  );

  const hideModal = () => setValue((prev) => ({ ...prev, visible: false }));

  const context = useMemo(() => {
    return { ...value, showModal, hideModal };
  }, [value, showModal]);

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};

export { ContextProvider, ModalContext };
